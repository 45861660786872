/* ModalComponent.css */

.fade-in {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}
