.is-highlight {
  font-weight: bold;
  color: white;
  background: linear-gradient(
    141deg,
    hsl(0, 100%, 31%) 0,
    #d19900 75%,
    #c0b301 100%
  );
  border-width: 0;
}
.is-highlight:hover {
  color: rgb(136, 190, 146);
  transition-duration: 0.5s;
}