/* FAQPage.css */

.faq-item {
  margin-bottom: 1.5rem;
}

.question {
  font-weight: bold;
}

.answer {
  margin-top: 0.5rem;
}
